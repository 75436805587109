<template lang="pug">
  v-app(ref="app")
    v-main
      router-view
</template>

<script>

export default { 
  name: 'App',
  mounted() {
    this.nioInitializeApplication(this)
    console.log("Version", process.env.APP_VERSION)
    console.log("Tacklebox Version", process.env.TACKLEBOX_VERSION)
    console.log("Commit", process.env.APP_GIT_HASH)
  },
  watch: {
    'nioCustomAppParameters': {
      deep: true,
      handler(val) {
        if (val.page === 'new/attribute') {
          if (val.selectedAttribute) {
            const attributeId = val.selectedAttribute.id
            parent.postMessage({
              name: 'clearCustomAppParameters'
            },"*")
            localStorage.setItem('nio:buyer-studio-custom-parameters', JSON.stringify({
              attributeId: attributeId
            }))
            parent.postMessage({
              name: 'pageNavigation',
              payload: `new`
            },"*")
          }
        } else if (val.page === 'new/schema-preset') {
          if (val.selectedSchemaPreset) {
            const schemaPresetId = val.selectedSchemaPreset
            localStorage.setItem('nio:buyer-studio-custom-parameters', JSON.stringify({
              schemaPresetId: schemaPresetId
            }))
            parent.postMessage({
              name: 'clearCustomAppParameters'
            },"*")
            parent.postMessage({
              name: 'pageNavigation',
              payload: `new/${schemaPresetId}`
            },"*")
          }
        }
      }
    }
  }
}
</script>

<style lang="sass">

@import "@narrative.io/tackle-box/src/styles/global/_fonts"
@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/global/_color-helpers"
@import "@narrative.io/tackle-box/src/styles/global/_typography"
@import "@narrative.io/tackle-box/src/styles/global/_breakpoints"

// global
.v-overlay.v-overlay--active.theme--dark .v-overlay__scrim
  background-color: $c-canvas-darker !important
  opacity: 0.9 !important
  backdrop-filter: blur(0.1875rem)
  box-shadow: none
  
.app-header
  display: flex
  justify-content: center
  align-items: flex-start

.app-dialog
  background-color: $c-white
  max-height: unset !important
  width: calc(100vw - 4rem)
  padding: 1.5rem
  border: 0.0625rem solid $c-primary-lighter !important
  box-shadow: 0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1) !important
  border-radius: 0.75rem !important
  
.cancel-dialog
  width: 40.625rem !important
  padding: 0rem !important
  box-shadow: 0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1) !important
  border-radius: 0.75rem !important

.app-loading
  width: 100%
  height: 100%
  position: relative
  .v-progress-circular
    position: fixed
    left: 50%
    top: 18.75rem
    margin-left: -2.5rem
    z-index: 2

.nio-step-content
  position: relative

.step-loading
  width: 100%
  height: 100%
  position: relative
  .v-progress-circular
    position: absolute
    left: 50%
    top: -0.9375rem
    margin-left: -2.5rem
    z-index: 2	

.step-header
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 0.5rem
  margin-bottom: 1.5rem
  p
    margin-top: 0.5rem

#app 
  font-family: 'Euclid Circular A'
</style>
