const NewPage = () => import('@/pages/new/NewPage')
const QuickStartPage = () => import('@/pages/quick-start/QuickStartPage')
const SubscriptionsPage = () => import('@/pages/subscriptions/SubscriptionsPage')
const NarrativeSchemaPresetsPage = () => import('@/pages/schema-presets/narrative/NarrativeSchemaPresetsPage')
const CustomSchemaPresetsListingPage = () => import('@/pages/schema-presets/custom/listing/CustomSchemaPresetsPage')
const NewSchemaPresetPage = () => import('@/pages/schema-presets/custom/new/NewSchemaPresetPage')
const Error = () => import('@/pages/error/Error')

export default [
	{ path: '/error', name: 'error', component: Error, props: true  },
	{ path: '/new', name: 'newPage', component: NewPage },
	{ path: '/new/:schemaPresetId', name: 'newPage', component: NewPage, props: true },
	{ path: '/subscriptions', name: 'subscriptionsPage', component: SubscriptionsPage },
	{ path: '/narrative-schema-presets', name: 'narrativeSchemaPresetsPage', component: NarrativeSchemaPresetsPage },
	{ path: '/custom-schema-presets', name: 'customSchemaPresetsListingPage', component: CustomSchemaPresetsListingPage },
	{ path: '/new-schema-preset', name: 'newSchemaPresetPage', component: NewSchemaPresetPage },
	{ path: '/quick-start', name: 'quickStartPage', component: QuickStartPage }
]
